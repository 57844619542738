<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div v-if="detailObj" v-loading="loading" class="preview-wrapper">
    <div class="preview-content">
      <div class="preview-top">
        <div>
          <p class="preview-title">
            {{ detailObj.projectName }}
          </p>
          <div class="collection-info">
            <span>{{ detailObj.goodsName }}</span>
          </div>
        </div>
        <div class="collection-content">
          <div class="collection-content-item">
            <p><span>集采单价</span><span class="unit-price">￥{{ detailObj.collectionUnitPrice }}/{{ $getDictNameByData(meterageUnitOptions, detailObj.meterageUnit) }}</span></p>
            <p><span>保证金</span><span>{{ detailObj.depositValue }}{{ detailObj.depositType == '1' ? '%' : '元' }}</span></p>
            <p><span>所在地区</span><span>{{ detailObj.placeProvinceName }}{{ detailObj.placeCityName }}{{ detailObj.placeTownName }}</span></p>
          </div>
          <div class="collection-content-item">
            <p><span>集采总量</span><span class="total">{{ detailObj.centralizedPurchaseTotalQuantity }}{{ $getDictNameByData(meterageUnitOptions, detailObj.meterageUnit) }}</span> </p>
            <p><span>报名截止时间</span><span>{{ detailObj.enterEndDate + ' 23:59:59' }}</span></p>
            <p><span>尾款缴纳截止时间</span><span>{{ detailObj.balancePaymentEndDate + ' 23:59:59' }}</span></p>
          </div>
          <div class="collection-content-count">
            <p>
              <span class="time">距结束</span><span class="time-color" :class="[detailObj.projectStatus == 2 ? '' : 'has-closed']">
                {{ enterEndDate.day || 0 }}</span><span>天</span>
              <span class="time-color" :class="[detailObj.projectStatus == 2 ? '' : 'has-closed']">{{ enterEndDate.hour || 0 }}</span><span>时</span>
              <span class="time-color" :class="[detailObj.projectStatus == 2 ? '' : 'has-closed']">{{ enterEndDate.minutes|| 0 }}</span><span>分</span>
              <span class="time-color" :class="[detailObj.projectStatus == 2 ? '' : 'has-closed']">{{ enterEndDate.second || 0 }}</span><span>秒</span>
            </p>
            <div class="collection-content-btn" :class="[detailObj.projectStatus == 2 ? '' : 'has-closed']" @click="register">
              <p>立即报名</p>
              <!-- <p>保证金￥1000.00</p> -->
              <p>保证金&nbsp;{{ detailObj.depositValue }}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="preview-content-table">
        <p><span>公告详情</span></p>
        <div class="table-info">
          <p>发布时间：{{ detailObj.publishTime || '空' }}</p>
          <p>项目编号：{{ detailObj.projectNo || '空' }}</p>
        </div>
        <div class="table-content">
          <table>
            <tr>
              <td colspan="2" class="background-label">
                集采项目名称
              </td>
              <td colspan="6">
                {{ detailObj.projectName }}
              </td>
            </tr>
            <tr>
              <td colspan="2" class="background-label">
                报名截止时间
              </td>
              <td colspan="2">
                {{ detailObj.enterEndDate + ' 23:59:59' }}
              </td>
              <td colspan="2" class="background-label">
                尾款缴纳截止时间
              </td>
              <td colspan="2">
                {{ detailObj.balancePaymentEndDate + ' 23:59:59' }}
              </td>
            </tr>
            <tr>
              <td colspan="8" class="background-label">
                商品信息
              </td>
            </tr>
            <tr>
              <td>品名</td>
              <td>材质</td>
              <td>规格</td>
              <td>产地</td>
              <td>件重</td>
              <td>集采总量</td>
              <td>最小认购量</td>
            </tr>
            <tr>
              <td>{{ detailObj.goodsName }}</td>
              <td>{{ detailObj.goodsMaterials }}</td>
              <td>{{ detailObj.goodsSpecs }}</td>
              <td>{{ detailObj.goodsProduct }}</td>
              <td>{{ detailObj.goodsWeights }}</td>
              <td>
                {{ detailObj.centralizedPurchaseTotalQuantity||0 }}{{ $getDictNameByData(
                  $store.getters.getDictionaryItem('purchaseUnit'),
                  detailObj.meterageUnit
                ) }}
              </td>
              <td>
                {{ detailObj.minSubscribeQuantity||0 }}{{ $getDictNameByData(
                  $store.getters.getDictionaryItem('purchaseUnit'),
                  detailObj.meterageUnit
                ) }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                其他指标参数
              </td>
              <td colspan="6">
                {{ detailObj.otherIndicatorParameters }}
              </td>
            </tr>
            <tr>
              <td colspan="2" class="background-label">
                集采单价（含税）
              </td>
              <td colspan="2">
                {{ detailObj.collectionUnitPrice || 0 }}元
              </td>
              <td colspan="2" class="background-label">
                当前市场单价
              </td>
              <td colspan="2">
                {{ detailObj.marketUnitPrice || 0 }}元
              </td>
            </tr>
            <tr>
              <td colspan="2" class="background-label">
                报名资格条件
              </td>
              <td colspan="6" class="text-left" />
              1.注册并审核通过成为小链科技供应链平台会员 <br>
              2.完成企业实名认证<br>
              3.拥有商城买家权限<br>
              // eslint-disable-next-line vue/no-parsing-error
              </td>
            </tr>
            <tr>
              <td colspan="2" class="background-label">
                报名规则
              </td>
              <td colspan="6" class="text-left">
                1.报名时需要缴纳保证金，如未缴纳视为无效报名 <br>
                2.报名时缴纳的保证金，作为认购货物货款的一部分
                <!-- 3.报名后可以追加认购数量 -->
              </td>
            </tr>
            <tr>
              <td colspan="2" class="background-label">
                尾款规则
              </td>
              <td colspan="6" class="text-left">
                报名后，认购数量被锁定，可支付尾款
              </td>
            </tr>

            <tr>
              <td colspan="2" class="background-label">
                履约约定
              </td>
              <td colspan="6" class="text-left">
                1.合同签订：报名并支付尾款后，在尾款截止支付时间之后{{ detailObj.autoEndDay || '1' }}个工作日内需线上签署《购销合同》。超期未签，视为认购终止<br>
                2.交货时间：商家确认集采成单后，将自动过户。合同签订后{{ detailObj.deliveryPeriod || '1' }}日内提货，超期需自行支付仓储费<br>
                3.交货地点：{{ detailObj.placeProvinceName }}{{ detailObj.placeCityName }}{{ detailObj.placeTownName }}{{ detailObj.placeDetailAddr }}<br>
                4.规格确认：按采购要求供货<br>
                5.验收方式：按合同要求验收
              </td>
            </tr>
            <tr>
              <td colspan="2" class="background-label">
                发票约定
              </td>
              <td colspan="6" class="text-left">
                13%增值税专用发票
              </td>
            </tr>
            <tr>
              <td colspan="2" class="background-label">
                资金及处置方式
              </td>
              <td colspan="6" class="text-left">
                1.保证金：{{ detailObj.depositValue }}{{ detailObj.depositType == '1' ? '%比例' : '元定额' }}<br>
                2.缴纳保证金后，需要在尾款截止时间前支付全部尾款<br>
                3.处置：集采终止时立即退还；拒签合同的或者超期未签合同/未确认的，立即退还；已成交的，保证金和尾款全数作为货款部分
              </td>
            </tr>
            <tr>
              <td colspan="2" class="background-label">
                资金缴纳方式
              </td>
              <td colspan="6" class="text-left">
                1.线上开通资金账户进行支付<br>
                2.支持账户： <span v-for="(item, index) in hasOpenedBankList" :key="index" class="bank-item">{{ item }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="background-label">
                联系方式
              </td>
              <td colspan="6" class="text-left">
                名称：{{ detailObj.publishCmpName }}<br>
                地址：{{ detailObj.cmpAddress }}<br>
                联系人：{{ detailObj.linkMan }}<br>
                联系方式：{{ detailObj.linkPhone }}<br>
                微信咨询：{{ detailObj.wechatNumber || '' }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      title="报名认购"
      width="30%"
    >
      <el-form
        ref="registerRef"
        :model="registerForm"
        :rules="registerRules"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="认购数量" prop="projectEnterQuantity">
          <el-input v-model="registerForm.projectEnterQuantity" type="number" :placeholder="projectEnterQuantityPlaceHolder" @blur="validateMin">
            <template slot="append">
              {{ $getDictNameByData($store.getters.getDictionaryItem('purchaseUnit'), detailObj.meterageUnit) }}
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="保证金" prop="depositAmount">
          <el-input v-model="registerForm.depositAmount" disabled />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmRegister">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <SelectAccount ref="selectAccount" :publish-cmp-code="detailObj.publishCmpCode" :money="registerForm.depositAmount" :settle-bank-id="detailObj.settleBankId" :has-opened-bank-list="hasOpenedBankList" @getAccount="getAccount" />
  </div>
</template>
<script>
import SelectAccount from '@/components/SelectAccount'
import { countDownFn, thousandsFractionsSmaller, roundUpToInteger } from '@/unit/index'
import { getCollectionDetail, enterProjectSubscribe, checkEnterProjectSubscribe } from '@/http/collection'
import { fundAccountInfoListSettleBankByCmpId } from '@/http/account'
import { Loading } from 'element-ui'
import { getSelectAreaTree } from '@/http/common'
export default {
  components: { SelectAccount },
  data() {
    var threeNumber = (rule, value, callback) => {
      if (value === '' || value == null || value === undefined) {
        if (rule.required) {
          callback(new Error('请输入'))
        }
      } else if (Number(value) < 0 || Number(value) === 0) {
        callback(new Error('正数值，限三位小数'))
      } else if ((String(value).length - (String(value).indexOf('.') + 1)) > 3 && String(value).indexOf('.') >= 0) {
        callback(new Error('正数值，限三位小数'))
      } else {
        callback()
      }
    }
    return {
      allCityOption: [],
      meterageUnitOptions: this.$store.getters.getDictionaryItem('purchaseUnit'),
      registerForm: {},
      projectEnterQuantityPlaceHolder: '请输入',
      registerRules: {
        projectEnterQuantity: [
          { required: true, message: '请输入认购数量', trigger: 'blur' },
          { validator: threeNumber, trigger: ['blur', 'change'] }
        ]
      },
      enterEndDate: {},
      timer: null,
      detailObj: {},
      userInfo: JSON.parse(localStorage.getItem(`mallFrontDeskUserInfo${this.$store.state.userCmpRole}`)) || {},
      settleBankList: this.$store.getters.getDictionaryItem('settleBankName'),
      hasOpenedBankList: [],
      loading: true,
      token: localStorage(`mallFrontDeskToken${this.$store.state.userCmpRole}`),
      dialogVisible: false
    }
  },
  watch: {
    'registerForm.projectEnterQuantity': {
      handler(val) {
        if (this.detailObj.depositType === 1) {
          this.registerForm.depositAmount = Number(roundUpToInteger(Number(this.registerForm.projectEnterQuantity) * Number(this.detailObj.centralizedPurchasePrice) * this.detailObj.depositValue / 100))
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // 获取所有的城市  在进行详情获取  要不然城市渲染不出来
    getSelectAreaTree('00', res => {
      this.getCity(res.data || [])
      this.init()
    }, () => {
      this.init()
    })
    // this.getBankByCmpId()
  },
  beforeDestroy() {
    window.clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    // 递归把省市区拿出来
    getCity(arr) {
      arr.forEach(item => {
        this.allCityOption.push(item)
        if (item.children && item.children.length > 0) {
          this.getCity(item.children)
        }
      })
    },
    init() {
      getCollectionDetail(this.$route.query.projectId || '', res => {
        this.detailObj = { ...res.data }
        this.allCityOption.forEach(item => {
          if (item.code === this.detailObj.placeCity) this.detailObj.placeCityName = item.name
          if (item.code === this.detailObj.placeTown) this.detailObj.placeTownName = item.name
          if (item.code === this.detailObj.placeProvince) this.detailObj.placeProvinceName = item.name
        })
        // 集采单价
        this.detailObj.collectionUnitPrice = thousandsFractionsSmaller(this.detailObj.centralizedPurchasePrice)
        // 市场价格
        this.detailObj.marketUnitPrice = thousandsFractionsSmaller(this.detailObj.currentMarketPrice)
        this.getBankByCmpId(this.detailObj.publishCmpCode)
        this.loading = false
        // 报名中
        if (this.detailObj.projectStatus === 2) {
          // 先渲染一次  要不然会出现0000的情况
          this.enterEndDate = countDownFn(`${this.detailObj.enterEndDate} 23:59:59`, 'retrunObj')
          this.timer && clearInterval(this.timer)
          this.timer = setInterval(() => {
            this.enterEndDate = countDownFn(`${this.detailObj.enterEndDate} 23:59:59`, 'retrunObj')
          }, 1000)
        }
      })
    },
    getBankByCmpId(publishCmpCode) {
      fundAccountInfoListSettleBankByCmpId(
        { cmpId: publishCmpCode }
        , res => {
          this.settleBankList.filter(bl => {
            res.data.some(cl => {
              if (bl.dictId === cl.settleBankId) {
                bl.flag = true
                this.hasOpenedBankList.push(bl.dictName)
              }
            })
            return bl
          })
        })
    },
    register() {
      if (this.detailObj.projectStatus !== 2) return
      if (!this.token || !this.token.length) {
        this.$message.warning('还未登录， 请登录')
        this.$router.push({ path: '/login' })
        return
      }
      if (this.$store.state.userCmpRole !== '02') return this.$message.warning('账号所属企业暂无买家权限')
      if (this.detailObj.publishCmpName === this.userInfo.orgName) return this.$message.warning('请勿报名自己企业的集采项目')
      if (!this.settleBankList.length) {
        this.$confirm(
          `企业当前暂未开通资金账户，请先开通商家支持的账户再进行认购`,
          '支付提醒',
          {
            confirmButtonText: '前往开通',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          window.open(`${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/fundsManage/settlementAccount`, '_blank')
        }).catch(() => {
          return
        })
      }
      checkEnterProjectSubscribe({ id: this.$route.query.projectId }, res => {
        this.dialogVisible = true
        if (this.detailObj.minSubscribeQuantity) {
          this.projectEnterQuantityPlaceHolder = `最小输入${this.detailObj.minSubscribeQuantity}`
        }
        if (this.detailObj.depositType === 2) {
          this.registerForm.depositAmount = this.detailObj.depositValue
        }
      })
    },
    confirmRegister() {
      this.$refs['registerRef'].validate((valid) => {
        if (valid) {
          if (this.registerForm.projectEnterQuantity > (this.detailObj.centralizedPurchaseTotalQuantity)) {
            return this.$message.warning('认购数量不能大于集采项目总量！！！')
          }
          if (!this.registerForm.projectEnterQuantity) {
            return this.$message.warning('请输入认购数量')
          }
          this.dialogVisible = false

          this.$refs['selectAccount'].dialogTableVisible = true
        }
      })
    },
    validateMin() {
      if (this.registerForm.projectEnterQuantity < this.detailObj.minSubscribeQuantity) {
        this.registerForm.projectEnterQuantity = this.detailObj.minSubscribeQuantity
      }
    },
    getAccount(row) {
      const loading = Loading.service({
        lock: true,
        text: '报名中...',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      enterProjectSubscribe({
        ...this.registerForm,
        settleBankId: row.settleBankId,
        id: this.$route.query.projectId
      }, res => {
        this.$refs['selectAccount'].dialogTableVisible = false
        loading.close()
        if (row.settleBankId === 'ABC') {
          window.open(res.data, '_blank')
          this.$confirm(
            `是否确认支付`,
            '报名成功',
            {
              confirmButtonText: '已经支付',
              cancelButtonText: '未支付',
              type: 'warning'
            }
          ).then(() => {
            window.open(`${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/collection/subscription`, '_blank')
          }).catch(() => {
            return
          })
        } else {
          this.$confirm(
            `恭喜您已成功报名该集采项目`,
            '报名成功',
            {
              confirmButtonText: '查看认购单',
              cancelButtonText: '取消',
              type: 'warning'
            }
          ).then(() => {
            window.open(`${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/collection/subscription`, '_blank')
          }).catch(() => {
            return
          })
        }
        // this.$message.success(res.msg)
      }, () => {
        loading.close()
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.preview-wrapper {
    width: $centerPlateWidth;
    margin: 18px auto 0;
    background: #fff;
    table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #000000;
    }
    th,td {
        border: 1px solid #000000;
        text-align: center;
    }
    td {
        padding: 10px;
    }
    .background-label {
        background-color: #ccc;
    }
    .text-left {
        text-align: left;
        .bank-item {
          margin-right: 10px;
        }
    }
    .preview-content {
        // padding: 20px 10px;
        .preview-top {
            border: 1px solid #E7E7E7;
            padding: 0 20px;
            .collection-content {
                display: flex;
                margin: 26px 0 25px;
                &-item {
                    margin-right: 40px;
                    >p {
                        height: 40px;
                        line-height:40px;
                        span:nth-of-type(1) {
                            margin-right: 24px;
                            color: #999999;
                            min-width: 65px;
                            display: inline-block;
                        }
                        .unit-price {
                          font-size: 24px;
                          font-weight: 500;
                          color: #D31E0E;
                        }
                        .total {
                           font-size: 24px;
                            font-weight: 500;
                        }
                    }
                }
                &-count {
                    display: flex;
                    flex-direction: column;
                    margin-left: auto;

                    > p {
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        margin-bottom: 10px;
                        .time {
                            font-weight: 500;
                            margin-right: 10px;

                        }
                        .time-color {
                            font-size: 24px;
                            font-weight: 500;
                            display: inline-block;
                            width: 40px;
                            color: #D31E0E;
                            opacity: 1;
                            background: rgba(211,30,14,0.06);
                            margin: 0 5px;
                        }

                    }

                }
                &-btn {
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    // justify-content: center;
                    margin-left: auto;
                    color: #fff;
                    width: 144px;
                    height: 44px;
                    font-size: 14px;
                    background: #D31E0E;
                    p:nth-of-type(1) {
                        margin-top: 4px;
                        font-weight: 500;
                        p{
                            height: 0;
                            line-height: 0;
                        }
                    }
                     p:nth-of-type(2) {
                      font-size: 12px;
                     }
                }
                .has-closed {
                  background: #F0F0F0 !important;
                  color: #999999 !important;
                }

            }
        }
       .preview-title {
            color: rgba(0,0,0,1);
            font-size: 24px;
            height: 40px;
            line-height: 40px;
            font-weight: 500;
            margin: 20px 0 10px 0;
        }
        .collection-info {
            margin: 10px 0;
            span {
                font-size: 14px;
                background: rgba(211,30,14,0.08);
                color: #D31E0E;
                padding: 5px;
            }
        }
        &-info {
            display: flex;
            // justify-content: space-between;
            padding: 30px;
            width: 100%;

            .preview-content-left {
                width: 50%;
                //  height: 80px;
                .top {
                    display: flex;
                    align-items: center;

                    p:nth-of-type(1) {
                        padding: 19px 10px;
                        width: 60px;
                        background: rgb(61, 187, 61);
                        color: #ccc;
                    }
                    p:nth-of-type(2) {
                        flex: 1;
                        height: 80px;
                        line-height: 80px;
                        // padding-left: 10px;
                        padding: 0 10px;
                        border: 1px dotted #E7E7E7;
                    }
                }
                .bottom {
                    height: 80px;
                    padding: 20px 0;
                    p {
                        display: flex;
                        line-height: 40px;
                        text-align: center;
                        span {
                            width: 50%;
                            border: 1px solid #9a9a9a;
                        }
                        span:nth-of-type(2) {

                            margin-left: -1px;
                        }
                    }
                    p:nth-of-type(2) {
                        margin-top: -1px;
                    }
                }
            }
           .preview-content-right {
                margin-left: 20%;
                p {
                    height: 40px;
                    line-height: 40px;
                }
                .register {
                    width: 200px;
                    height: 50px;
                    margin-top: 40px;
                }

           }
        }
        &-table {
            margin-top: 24px;
            width: 100%;
            border: 1px solid #E7E7E7;
            border-top: none;
            > p {
                height: 46px;
                line-height: 46px;
                background: rgba(0, 0, 0, 0.03);
                span {
                    text-align: center;
                    display: inline-block;
                    width: 112px;
                    border-top: 1px solid #D31E0E;
                    background: #fff;
                }
            }
            // padding: 30px;
            .table-info {
                display: flex;
                justify-content: space-between;
                height: 60px;
                line-height: 60px;
                padding: 0 24px;
            }
            .table-content {
                padding: 0 24px 24px;
            }

        }
    }

}
</style>
